/* Variable Declaration */
:root {
  --general-padding: 4rem 12.5vw;
  --general-padding-2: 150px 15vw;
  --faint-gray: rgb(240, 240, 240);
  --green: #08843b;
  --turquoise: #34a2c5;
}


/* General Styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  position: relative;
}
main {
  position: relative;
}
h1 {
  font-size: 3rem;
  line-height: 3.6rem;
  letter-spacing: -0.03rem;
}
h2 {
  font-size: 2.5rem;
  padding-bottom: 1rem;
  line-height: 2.75rem;
}
h3 {
  font-size: 1.5rem;
}
h4 {
  font-size: 1.4rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
p {
  font-size: 1.2rem;
  line-height: 1.7rem;
}
li {
  font-size: 1.2rem;
}
button {
  border: none;
  background: none;
  font-size: 1rem;
}
section {
  position: relative;
}
.section {
  padding: var(--general-padding);
}
.centred {
  text-align: center;
}
.slant, .slant2 {
  pointer-events: none;
  position: absolute;
  bottom: -5%;
  left: 0;
  width: 100%;
  height: 130px;
  overflow: hidden;
  z-index: 50;
}
.slant2 {
  bottom: -10%;
}

a {text-decoration: none;}
a:link {color: rgb(255, 255, 255);}
a:visited {color: black;}
a:active {color: black}


/* Button Styling */
.btn {
  display: inline-flex;
  background-color: white;
  color:black;
  border: 2px solid black;
  padding: 1rem;
  margin: auto;
  white-space: nowrap;
  border-radius: 10px;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  cursor: pointer;
}
.btn:hover {
  background-color: var(--green);
  border-color: var(--green);
  color: white;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}


/* Body Styling */
body {
  background-color: white;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
}


/* Navbar formatting */
.navbar {
  display: flex;
  background-color: rgb(255, 255, 255, 0);
  color: rgb(255, 0, 0) !important;
  box-shadow: 0 0 4px rgba(128, 128, 128, 0);
  height: 5rem;
  position: sticky;
  z-index: 100;
  top: 0px;
  padding-right: 12.5vw;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.menu {
  width: 100%;
  display: flex;
  list-style: none;
  padding-top: 0.5rem;
  margin: auto;
  justify-content: space-around;
}
.Logo {
  padding: 0.6rem 8rem 0 12.5vw;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.8) 60%, rgba(255, 255, 255, 0.0));
}
.navlink {
  text-transform: uppercase;
  position: relative;
  width: 200px;
  padding: 0.1rem 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 1.2rem;
  font-weight: 500;
}
.navlink:hover {
  color: var(--green);
}
.navlink::before {
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  height: 2px;
  bottom: -0.7rem;
  width: 0%;
  content: "";
  position: absolute;
  background-color: var(--green);
}
.navlink:hover::before {
  width: 100%;
}
.hamburger {
  display: none;
  cursor: pointer;
  z-index: 110;
}
.bar {
  display: block;
  width: 25px;
  height: 2px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.5s ease-in-out;
  background-color: black;
}
.overlay {
  pointer-events: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: black;
  z-index: 0;
  opacity: 0;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
.Home {
  width: 0;
  opacity: 0;
  pointer-events: none;
}
@media(max-width:1245px) {
  .Logo {
    background-image: none;
    padding-right: 0;
  }
  .navbar {
    background-color: rgba(255, 255, 255, 0.6) !important;
    box-shadow: 0 0 4px rgba(128, 128, 128, 1) !important;
    backdrop-filter: blur(5px);
    padding-right: 0;
  }
  .Home {
    width: 100%;
    opacity: 1;
    pointer-events: all;
  }
  .hamburger {
    display: inline-block;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    padding-right: 1.5rem;
}
  .Logo {
    padding-left: 2rem;
    margin-left: auto;
  }
  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }
  .hamburger.active .bar:nth-child(1) {
    transform: translateY(7px) rotate(45deg);
  }
  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-7px) rotate(-45deg);
  }
  .menu {
    position: fixed;
    opacity: 0;
    top: 0%;
    right: 0;
    overflow: hidden;
    flex-direction: column;
    background-color: white;
    width: 0%;
    height: 100vh;
    padding-top: 6rem;
    z-index: 2;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
    justify-content: start;
    white-space: nowrap;
  }
  .navlink {
    /* white-space: nowrap; */
    text-align: right;
    margin: 0;
    color: black !important;
  }
  .navlink::before {
    height: 0px;
  }
  .navlink:hover {
    padding-left: 5px;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
  }
  .navitem {
    margin: 2vh 0 4vh 0;
    padding-bottom: 2rem;
    border-bottom: 2px solid var(--faint-gray);
  }
  .menu.active {
    width: 55%;
    opacity: 1;
    padding: 6rem 8vw 0 8vw;
    -webkit-transition: 0.3s ease 0.2s;
    transition: all 0.3s ease 0.2s;
  }
  .overlay.active {
    pointer-events: all;
    visibility: visible;
    opacity: 0.7;
  }
}
@media(max-width: 615px) {
  .menu.active {
    width: 70%;
  }
}
@media(max-width: 445px) {
  .menu {
    white-space: wrap;
  }
}

/* Banner Styling */
#banner {
  position: relative;
  background-color: rgb(199, 199, 199);
  background-image: url("./assets/Banner.jpg");
  background-size: cover;
  background-position: 0% 30%;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  padding: 0 12.5vw;
  height: 800px;
  color: black;
  margin: -80px auto;
}
#blry {
  position: absolute;
  margin-right: 12.5vw;
  top: 280px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  box-shadow: -8px 8px 15px 0px rgba(0, 0, 0, 0.5);
}
#banner h1 {
  margin: 50px;
  max-width: 40rem;
}
#overview {
  max-width: 45rem;
  margin: 0 40px 60px;
}
#bSlant {
  margin-top: 30px;
  width: 120%;
  height: 70%;
  background-color: #ffffff;
  transform: rotate(-2deg);
}
@media(max-width: 800px) {
  #blry {
    top: 180px;
  }
  #banner h1 {
    font-size: 2.5rem;
  }
}
@media(max-width: 500px) {
  #blry {
    top: 50vw;
  }
  #banner h1 {
    font-size: 2rem;
    margin: 20px;
    max-width: 40rem;
  }
  #overview {
    max-width: 45rem;
    margin: 0 20px 20px;
  }
}


/* Sections Sytling */
/* about section */
#about {
  display: flex;
  display: flex;
  margin: auto;
  padding: 150px 15vw;
}
#abtInfo {
  width: 100%;
  margin: auto 0px;
}
#about p {
  margin: 2rem 0 4rem;
  padding-right: 3.5rem;
}
#about img {
  object-fit: cover;
  margin: auto 0;
  width: min(600px, 35vw);
  height: min(600px, 35vw);
  border-radius: 10px;
}
#about div:nth-child(1) {
  margin-right: 20px;
}
#learn_more {
  margin: auto;
  white-space: nowrap;
}
#aSlant {
  margin-left: -5px;
  width: 120%;
  height: 80%;
  background-color: #ffffff;
  transform: rotate(2deg);
}
@media(max-width:1245px) {
  #about img {
    width: 90% !important;
    margin: 2rem auto 0;
  }
  #about div:nth-child(1) {
    margin-right: 0;
  }
}

/* products and services section */
#products_and_services {
  position: relative;
  background-color: var(--green);
  background-image: url("./assets/P\ and\ S.jpg");
  background-size: cover;
  background-position: 0% 30%;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  color: white;
  text-align: center;
  padding-top: 200px;
  padding-bottom: 200px;
}
#products_and_services p {
  margin: 1rem 0 3rem;
}
.cols {
  margin: auto;
  width: 100%;
  display: flex;
  padding-top: 2rem;
}
.cols a {
  position: relative;
  flex: 1;
  color: white;
  text-align:left;
  margin-right: 1rem;
  border: 2px solid rgb(255, 255, 255, 0);
  border-radius: 1rem;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.psIcons {
  display: flex;
  padding: 1rem;
  text-align: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.psIcons div {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.5);
  padding: 1.5rem;
  width: 225px;
  margin: 1rem 0;
}
.psIcons img {
  filter: invert();
  object-fit: cover;
  width: 150px;
  height: 150px;
}
.psIcons h4 {
  margin: auto;
  font-weight: 300;
}
.cols li {
  padding: 0.5rem 0;
  line-height: 1.5rem;
  list-style: none;
  text-align: center;
}
.cols a::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-top-width: 2px;
  border-bottom-width: 2px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: rgba(255,255,255,0.5);
  border-bottom-color: rgba(255,255,255,0.5);
  transform: scale(0.1, 1);
}
.cols a:hover::before {
opacity: 1;
transform: scale(1, 1);
}

@media(max-width: 1245px) {
  .psIcons div {
    padding: 1rem;
  }
  .psIcons img {
    width: 100px !important;
    height: 100px !important;
  }
  .psIcons h4 {
    padding: 0;
  }
}


/* Latest News Styling */
/* #articles {
  display: flex;
  overflow-x: hidden;
  flex-wrap: wrap;
  max-height: 30rem;
  overflow-y: hidden;
  justify-content: center;
}
.news {
  background-color:var(--faint-gray);
  margin: 1rem;
  max-width: 26rem;
  min-width: 18rem;
  max-height: 30rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.news ul {
  display: block;
  list-style: none;
}
.news li {
  padding-top: 1rem
}
.news_body {
  padding-right: 1rem;
  padding-left: 1rem;
}
.news_img {
  max-width: 26rem;
  min-width: 18rem;
}
.news:hover {
  scale: 1.05;
  box-shadow: 0 0 10px rgb(104, 104, 104);
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
#see_more {
  padding-top: 1rem;
  text-align: center;
} */

/* Gallery Styling */
.gallery {
  width: 100%;
}
.above {
  margin-top: 50px;
  position: relative;
  z-index: 99;
}
#gal {
  background-color: rgb(206, 206, 206);
  background-image: url("./assets/Logo.png");
  background-size: 40%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-blend-mode: multiply;

  display: flex;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border-radius: 20px;
  margin-top: 30px;
}
#gal::-webkit-scrollbar {
  display: none;
}
.VA {
  padding-left: 30px;
  padding-right: 30px;
  margin: 50px calc(50% - 59.865px);
}
.peek, .grid {
  border-radius: 20px;
  object-fit: cover;
  width: 300px;
  height: 300px;
  margin: 20px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.peek {
  box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.5);
  opacity: 0.95;
}
.grid {
  width: 200px;
  height: 200px;
}
#capt {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 130px;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-image: linear-gradient( rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
  z-index: 300;
}
#capCon {
  position: absolute;
  bottom: 0;
  left: calc(50% - min(34vw, 510px));
  width: min(68vw, 1020px);
  text-align: center;
  margin: 2rem 0;
  color: white;
}
.pics.active {
  object-fit: contain;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-radius: 0;
  margin: auto;
  background-color: black;
}
.pics.inactive {
  display: none;
  pointer-events: none;
}
#window {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  width: min(80vw, 1200px);
  height: min(50vw, 750px);
  top: calc(50% - min(25vw, 375px));
  left: calc(50% - min(40vw, 600px));
  background-color: white;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  z-index: 150;
  border-radius: 10px;
}
#window.active {
  opacity: 1;
  pointer-events: all;
}
.gTitle {
  position: absolute;
  width: 200px;
  top: -70px;
  left: calc(50% - 100px);
  color: white;
}
#picContainer {
  position: relative;
  margin: 1.1rem auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow-y: scroll;
  width: min(75vw, 1125px);
  height: min(46vw, 690px);
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
#picContainer.active {
  width: min(68vw, 1020px);
  margin: min(2vw, 30px) auto;
}
#picContainer::-webkit-scrollbar {
  width: 10px;
}
#picContainer.active::-webkit-scrollbar {
  width: 0px;
}
#picContainer::-webkit-scrollbar-track {
  border-radius: 10px;
}
#picContainer::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
#picContainer.active:hover #capt {
  opacity: 1;
}
#obscure {
  position: absolute;
  pointer-events: none;
  top: 2.3%;
  left: 1%;
  margin: auto;
  width: min(75vw, 1125px);
  height: min(47vw, 705px);
  background-image: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 3%, rgba(255, 255, 255, 0) 92%, rgba(255, 255, 255, 1) 97%);
  z-index: 200;
}
#obscure.hide {
  opacity: 0;
}
.overlay2 {
  pointer-events: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  z-index: 110;
  opacity: 0;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
.overlay2.active {
  pointer-events: all;
  opacity: 0.9;
}
.overlay2 span {
  position: absolute;
  top: 5%;
  right: 4%;
  cursor: pointer;
  color: rgb(168, 168, 168);
  font-size: 1.3rem;
}
.grid:hover {
  transform: scale(1.05);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#togGrid {
  position: absolute;
  top: 4%;
  left: 3.4%;
  font-size: 2rem;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#picPrev, #picNext {
  position: absolute;
  font-size: 5rem;
  top: calc(50% - 2.5rem);
  color: black;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
}
#picPrev.active, #picNext.active, #togGrid.active {
  opacity: 1;
  pointer-events: all;
}
#picPrev {
  left: 3%;
}
#picNext {
  right: 3%;
}
#picPrev.no, #picNext.no {
  color: gray;
  pointer-events: none;
}
#picPrev:hover, #picNext:hover, #togGrid:hover {
  transform: scale(1.1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media(max-width: 1040px) {
  #togGrid {
    left: 2%;
  }
  #picPrev {
    font-size: 4rem;
    left: 2%;
  }
  #picNext {
    font-size: 4rem;
    right: 2%;
  }
}
@media(max-width: 800px) {
  #togGrid {
    top: -4rem;
    color: white;
  }
  #picPrev {
    top: 110%;
    color: white;
    font-size: 5rem;
    left: 30%;
    padding: 15px 0 15px 15px;
  }
  #picNext {
    top: 110%;
    color: white;
    font-size: 5rem;
    right: 30%;
    padding: 15px 15px 15px 0;
  }
  #window.active {
    background-color: rgba(255, 255, 255, 0);
    min-height: 450px;
    top: calc(50% - 225px);
  }
  #picContainer {
    min-height: 450px !important;
  }
  #obscure {
    visibility: hidden;
  }
  .overlay2.active {
    opacity: 0.93;
    background-color: #021b0c;
  }
  .overlay2.active span {
    right: 10%;
  }
  #picContainer::-webkit-scrollbar {
    width: 4px;
  }
  #picContainer::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.7);
    border-radius: 5px;
  }
}
@media(max-width: 500px) {
  .peek {
    border-radius: 10px;
    width: 180px;
    height: 180px;
    margin: 15px;
  }
  .grid {
    border-radius: 10px;
    width: 110px;
    height: 110px;
    margin: 5px;
  }
}


/* Partners Styling */
#partners {
  position: relative;
  background-color: var(--green);
  background-image: url("./assets/TXTR.png");
  background-size: cover;
  background-position: 0% 30%;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  color: white;
  text-align: center;
  padding-top: 200px;
  padding-bottom: 200px;
}

#partners div {
  display: flex;
  text-align: center;
  overflow-x: hidden;
  flex-wrap: wrap;
  justify-content: center;
}
.ptnr {
  margin: 2rem;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  backdrop-filter: blur(5px);
  padding: 0 30px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}
.ptnr img {
  max-width: 120px !important;
}
.ptnr a {
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}
.ptnr a:hover {
  transform: scale(1.05);
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}

/* Footer Styling */
footer {
  width: 100%;
  display: flex;
  padding: 1rem 12.5vw;
  border-top: 2px solid var(--faint-gray);
}
footer img {
  flex: 1;
  width: 30vw;
  max-width: 150px;
  padding-right: 4vw;
  margin: auto;
}
footer #copyright {
  margin: 2rem 7.5vw 2rem 0;
  font-size: 0.8rem;
}
#fLeft {
  display: flex;
  flex-direction: column;
  border-right: 2px solid var(--faint-gray);
}
#fRight {
  display: flex;
  flex-direction: column;
  margin: auto 7.5vw;
}
.navlink2 {
  margin-bottom: 1rem;
}


/* Coming Soon Page */
.soon h1 {
  padding-top: 30vh;
  padding-bottom: 3rem;
  text-align: center;
  color: var(--green);
}
.soon h3 {
  margin-bottom: auto;
  padding-bottom: 40vh;
  text-align: center;
}



/* Contact Us Page */
#contact_head {
  padding: 200px 12.5vw 120px;
  background-color: rgb(129, 129, 129);
  background-image: url("./assets/contact.jpg");
  background-size: cover;
  background-position: 0% 30%;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  color: white;
  margin-top: -80px;
}
#contact_head h1 {
  font-size: 2.5rem;
  padding-top: 1rem;
}
.locations {
  padding: var(--general-padding);
  display: flex;
}
#text {
  flex: 2;
}
.locations h2 {
  border-bottom: 2px solid var(--faint-gray);
}
.locations ul {
  list-style: none;
  padding-top: 2rem;
  white-space: nowrap;
}
iframe {
  height: 300px;
  width: 450px;
  margin-left: 3rem;
}
#cIcons {
  display: flex;
  margin-top: 80px;
  height: 60px;
  width: 100%;
}
#phone {
  padding-top: 4px;
  cursor: pointer;
}
#email, #phone {
  width: calc(60px + 1rem);
  height: calc(60px + 1rem);
  display: flex;
  overflow: hidden !important;
  margin: 1rem 1rem 1rem 0;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
#email p, #phone p {
  white-space: nowrap;
  opacity: 0;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  padding-top: 15px;
  margin-left: 1rem;
  color: black;
}
#email:hover, #phone:hover {
  width: calc(370px + 1rem);
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
#email:hover p, #phone:hover p {
  opacity: 1;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
@media(max-width: 875px) {
  iframe {
      display: none;
  }
}
@media(max-width: 650px) {
  #cIcons {
      flex-direction: column;
      height: 250px;
  }
  #email p, #phone p {
      font-size: 1rem;
  }
}



/* About Us Page */
#about_head {
  padding: 280px 12.5vw 200px;
  background-color: rgb(129, 129, 129);
  background-image: url("./assets/Solar.jpg");
  background-size: cover;
  background-position: 0% 30%;
  background-repeat: no-repeat;
  background-blend-mode: multiply;
  color: white;
  margin-top: -80px;
}
#about_head h1 {
  font-size: 2.5rem;
  padding-top: 1rem;
}
.abt {
  display: flex;
  justify-content: space-around;
  padding: var(--general-padding-2);
  border-bottom: 2px solid var(--faint-gray);
}
.abt img {
  object-fit: cover;
  object-position: 70% 0%;
  width: 500px;
  height: 500px;
  margin: auto;
}
.abt p {
  margin-top: 2rem;
  width: 600px;
  font-size: 1.5rem;
  line-height: 2rem;
}
.info {
  margin: auto 2rem;
  height: fit-content;
}
.abt ul {
  padding-left: 1rem;
  width: 630px;
}
.abt li {
  padding-top: 1rem;
}
@media(max-width: 1245px) {
  .abt, .idx {
      flex-direction: column;
  }
  .abt2 {
      flex-direction: column-reverse;
  }
  /* .abt3 p, .idx {
      text-align: center;
  } */
  .idx p {
      padding: 0 !important;
  }
  .abt img {
      margin-top: 2rem;
  }
  .idx img {
      margin: 2rem auto;
  }
  /* .abt h2 {
      text-align: center;
  } */
  /* .abt p, .abt ul {
      margin: auto;
  } */
  .abt p, .info ul {
    width: 100%;
    margin-top: 0;
  }
  .info {
    width: 70vw;
    max-width: 650px;
    margin: auto;
  }
}
@media(max-width: 890px) {
  .abt {
    padding-left: 10vw;
    padding-right: 10vw;
  }
  .abt img, .idx img {
    width: 70vw !important;
    max-width: 650px;
  }
}


/* Products and Services Styling */
#PandS {
  padding: 80px 15vw;
}
#PandS h1 {
  margin-bottom: 50px;
  text-align: center;
}
#psItems {
  display: flex;
}
#ps {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 830px;
  margin-right: 2rem;
}
#ps h3 {
  font-size: 1.2rem;
}
#Products, #Services {
  width: 300px;
  height: 400px;
  background-color: var(--faint-gray);
  text-align: center;
  padding: 2rem;
  border-radius: 5px;
  margin-right: 0;
}
#Products h2, #Services h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}
#Products {
  margin-bottom: 2rem;
}
#prod, #serv {
  text-align: center;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  padding: 1rem;
}
#prod:hover .psImg, #serv:hover .psImg {
  transform: scale(1.1);
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
.psImg {
  object-fit: cover;
  max-height: 150px;
  max-width: 200px;
  margin-bottom: 1rem;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
#psContent {
  text-align: center;
}
#psContent h1 {
  margin-bottom: 40px;
}
#psPics {
  display: flex;
  justify-content: space-around;
  margin-bottom: 40px;
  padding: 0 3vw;
}
#psPics img {
  object-fit: cover;
  max-width: 300px;
  max-height: 300px;
  border-radius: 10px;
}
#psDesc {
  background-color: var(--faint-gray);
  height: 830px;
  width: 100%;
  border-radius: 5px;
  padding: 3rem;
}
#generic {
  position: absolute;
  width: 300px;
  height: 50px;
  top: calc(50% - 25px);
  left: calc(50% - 150px);
}
@media(max-width: 1550px) {
  #psPics img {
    max-width: 15vw;
    max-height: 15vw;
  }
}
@media(max-width: 1245px) {
  #ps {
    flex-direction: row;
    margin-right: 0;
    justify-content: space-around;
    height: 300px;
  }
  #Products {
    margin: 0;
  }
  .psImg {
    max-height: 100px;
    max-width: 150px;
  }
  #Products, #Services {
    width: 350px;
    height: 270px;
    padding: 1rem;
  }
  #Products h2, #Services h2 {
    margin: 0;
    font-size: 1.5rem;
  }
  #prod h3, #serv h3 {
    font-size: 1rem;
    width: 250px;
  }
  #psItems {
    flex-direction: column;
  }
  #psPics img {
    max-width: 250px;
    max-height: 250px;
  }
  #psContent h1 {
    font-size: 2rem;
  }
  #psDesc {
    height: 600px;
    padding: 2rem 1rem;
  }
}
@media(max-width: 1045px) {
  #prod h3, #serv h3 {
    font-size: 1rem;
    width: 150px;
    font-weight: 300;
  }
  #Products, #Services{
    width: 250px;
  }
  #Products h2, #Services h2 {
    padding: 0;
  }
  #psPics img {
    max-width: 20vw;
    max-height: 20vw;
    min-width: 120px;
    min-height: 120px;
  }
}
@media(max-width: 900px) {
  #PandS {
    padding: 80px 7.5vw;
  }
  #PandS h1 {
    font-size: 2.3rem;
    line-height: 2.5rem;
  }
}
@media(max-width: 620px) {
  #psContent h1 {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
  #ps {
    flex-direction: column;
    margin: 80px auto 90px;
    width: 100%;
  }
  .psImg {
    margin: 0 1.5rem 0 0;
  }
  #Products, #Services {
    width: 100%;
    height: 198px !important;
    padding: 1rem 0 1rem 0.5rem;
  }
  #Products {
    margin-bottom: 10px;
  }
  #prod, #serv {
    display: flex;
    margin: auto;
    padding: 1rem 5px;
  }
  #prod h3, #serv h3 {
    margin: auto;
    text-align: left;
  }
  #Products h2, #Services h2 {
    margin: -10px 0.5rem 0 0;
    font-size: 1.2rem;
  }
  #prod h3, #serv h3 {
    width: 110px;
  }
}

/* 404 */
.notFound {
  text-align: center;
  width: 100%;
  height: 70vh;
  border-top: 1px solid var(--green);
}
.notFound h3 {
  color: var(--green);
  margin: 100px 0 30px;
}
.notFound p {
  margin: 3rem auto 10rem;
  max-width: 700px;
}